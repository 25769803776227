<template>
  <div class="center grid">
    <vs-row>
      <vs-col w="2"/>

      <vs-col align="right" justify="center" w="4">
        <box-icon name='error' color="#ff4d4d" size="200px"></box-icon>
      </vs-col>
      <vs-col align="left" w="4">
        <h1 class="title" >Oops! 404</h1>
        <h2 class="subtitle">
          Page Not Found
        </h2>
      </vs-col>
      
      <vs-col w="2"/>
    </vs-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped>
    
    .title
        color: black
        margin: 40px 0px 0px 0px
    .subtitle
        color: silver
        width: 70%
</style>